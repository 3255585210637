import { render, staticRenderFns } from "./DepatmentStat.vue?vue&type=template&id=037e9afa&scoped=true&"
import script from "./DepatmentStat.vue?vue&type=script&lang=js&"
export * from "./DepatmentStat.vue?vue&type=script&lang=js&"
import style0 from "./DepatmentStat.vue?vue&type=style&index=0&id=037e9afa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037e9afa",
  null
  
)

export default component.exports